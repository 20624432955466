import React from "react";

const Radio = ({ item, name, radioChange, children }) => {
  const complexId = `${name}-${item.id}`;

  return (
    <div className="radio">
      <input
        type="radio"
        name={ name }
        value={ item.id }
        id={ complexId }
        onChange={ () => {
          radioChange(item);
        } }
      />
      <label htmlFor={ complexId } className="radio_label">
        { children }
      </label>
    </div>
  );
};

export default Radio;


