import React, { useState, useEffect } from 'react';
import { map, includes, filter } from 'lodash';
import classnames from 'classnames';
import Http from '../../../../../../common/http';
import { onDownloadClick, onDownloadIframeClick } from './helper';
import DownloadPopup from './downloadPopup';

const DownloadIndicator = () => {
  const [ addHovered, setAddHovered ] = useState(false);
  const [ userDownloads, setUserDownloads ] = useState([]);
  const [ popupData, setPopupData ] = useState(null);
  const [ checkListeners, setCheckListeners ] = useState(null);
  const downloadsEmpty = !userDownloads.length;
  const finishedCount = filter(
    userDownloads,
    (el) => (includes([ 'finished', 'failed' ], el.status))
  ).length;
  const checkStatus = (forceOneTime) => {
    const defaultInterval = 5000;
    Http.get('/dashboard/user_downloads.json', {}).then(
      (response) => {
        setUserDownloads(response.data.user_downloads);
        const anyInProgress = response.data.any_in_progress;

        if (!forceOneTime && anyInProgress) {
          setTimeout(checkStatus, defaultInterval);
        }
      }
    );
  };

  useEffect(() => {
    const destroyDownloadFunc = onDownloadClick((data) => {
      const wrapperPosition = document.querySelector('.main-wrapper').getBoundingClientRect();
      const sidebarPosition = document.querySelector('.sidebar').getBoundingClientRect();

      const left = data.iframe ? data.x + sidebarPosition.width : data.x;
      setPopupData({
        left: left + document.body.scrollLeft,
        top: data.y + document.body.scrollTop,
        offsetX: left - wrapperPosition.left,
        offsetY: data.y - wrapperPosition.top,
        customText: data.customText
      });
      checkStatus();
    });
    const destroyDownloadIframeFunc = onDownloadIframeClick(() => {
      setAddHovered(false);
    });
    setTimeout(checkStatus, 1000);
    return () => {
      destroyDownloadFunc();
      destroyDownloadIframeFunc();
    };
  }, []);

  const popupOnClose = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    setPopupData(null);
  };

  useEffect(() => {
    const handler = () => {
      setAddHovered(false);
    };
    if (checkListeners) {
      document.addEventListener('touchstart', handler);
      document.addEventListener('mousedown', handler);
      setAddHovered(true);
    }
    popupOnClose();

    return () => {
      document.removeEventListener('touchstart', handler);
      document.removeEventListener('mousedown', handler);
    };
  }, [ checkListeners ]);

  const popupOnShowList = () => {
    setCheckListeners({});
  };

  const targetOption = (file) => (
    includes([ "google", "google_spreadsheet" ], file.format) ? "_blank" : "_self"
  );

  return (
    <li
      className={
        classnames(
          "sidebar_nav-list-item dropdown -on-hover -sidebar",
          { '-hovered': addHovered }
        )
      }
    >
      <span className="sidebar_nav-link -with-icon dropdown_toggle" role="button">
        <svg className="sidebar_nav-link-icon icon -menu-downloads">
          <use xlinkHref="#svg_menu-downloads" />
        </svg>
        {
          downloadsEmpty ?
            (
              <span className="sidebar_nav-link-text">Downloads</span>
            ) :
            (
              <span className="sidebar_nav-link-text">
                { finishedCount } of { userDownloads.length }
              </span>
            )
        }
      </span>
      <div className="dropdown_container -to-right -corner-bottom -corner-on -sidebar-popup">
        <div className="dropdown_inset">
          <div className="dropdown_content">
            <div className="selector-content">
              <div className="sidebar-processing">
                {
                  downloadsEmpty &&
                  <div className="sidebar-processing_empty">
                    No active downloads
                  </div>
                }
                {
                  !downloadsEmpty &&
                  <div className="sidebar-processing_content">
                    <table className="table table-processing -no-side-padding -no-margin">
                      <tbody>
                        {
                          map(userDownloads, (file) => (
                            <tr key={ `file-${file.id}` }>
                              <td className="table-processing_name">
                                <div className="processing-name">
                                  {
                                    file.status === 'finished' ?
                                      (
                                        <a
                                          className="processing-name_title-link"
                                          href={ file.s3_filename }
                                          target={ targetOption(file) }
                                        >
                                          { file.filename }
                                          {
                                            !!file.format_title &&
                                            <>
                                              { ' ' }
                                              <span>({ file.format_title })</span>
                                            </>
                                          }
                                        </a>
                                      ) :
                                      (
                                        <div className="processing-name_title">
                                          { file.filename }
                                          {
                                            !!file.format_title &&
                                            <>
                                              { ' ' }
                                              <span>({ file.format_title })</span>
                                            </>
                                          }
                                        </div>
                                      )
                                  }
                                  {
                                    !!file.filters &&
                                    <div className="processing-name_subtitle">
                                      Filters: { file.filters }
                                    </div>
                                  }
                                  <div className="processing-name_subtitle">{ file.subtitle }</div>
                                </div>
                              </td>

                              <td className="table_control">
                                {
                                  file.status === 'in_progress' &&
                                  <span
                                    className="processing-spinner"
                                    title="File is being prepared..."
                                  >
                                    <svg
                                      className="processing-spinner_icon icon -i-process-pending"
                                    >
                                      <use xlinkHref="#svg_i-process-pending" />
                                    </svg>
                                  </span>
                                }
                                {
                                  file.status === 'finished' &&
                                  <a
                                    className="icon-link -square"
                                    href={ file.s3_filename }
                                    title="Download export file"
                                    target={ targetOption(file) }
                                  >
                                    <svg className="icon-link_icon icon -i-process-download">
                                      <use xlinkHref="#svg_i-process-download" />
                                    </svg>
                                  </a>
                                }
                                {
                                  file.status === 'failed' &&
                                  <span title="Error occurred, please try again...">
                                    <svg className="icon -i-error">
                                      <use xlinkHref="#svg_i-error" />
                                    </svg>
                                  </span>
                                }
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        !!popupData &&
        <DownloadPopup
          left={ popupData.left }
          top={ popupData.top }
          offsetX={ popupData.offsetX }
          customText={ popupData.customText }
          onShowList={ popupOnShowList }
          onClose={ popupOnClose }
        />
      }
    </li>
  );
};

export default DownloadIndicator;
