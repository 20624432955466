import React, { useContext } from "react";
import classnames from "classnames";
import { GlobalContext } from '../context';

const PaymentButton = ({ payWith, payment, onClick }) => {
  const { translate, countryCode, cell } = useContext(GlobalContext);
  const icon = payment[`icon-cell${cell}`] || payment.icon;
  return (
    <button
      className={
        classnames("button -white -full-width payment-button", payment.id, `cell-${cell}`)
      }
      onClick={ () => onClick(payment) }
    >
      {
        payWith &&
        <span className="pay-with">{ translate("payWith", countryCode) }{ " " }</span>
      }
      {
        !!icon &&
        <svg className={ classnames("payment-icon", payment.id, `cell-${cell}`) }>
          <use xlinkHref={ `#svg_${icon}` } />
        </svg>
      }
      {
        payment.buttonText &&
        <span className="payment-button_title">{ payment.title(countryCode) }</span>
      }
    </button>
  );
};

export default PaymentButton;
