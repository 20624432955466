const translations = {
  navigationHome: { en: 'Home', de: 'Heim' },
  navigationProducts: { en: 'Products', de: 'Produkte' },
  navigationAbout: { en: 'About', de: 'Über uns' },
  navigationSupport: { en: 'Support', de: 'Unterstützung' },
  navigationContact: { en: 'Contact', de: 'Kontakt' },
  addToCart: { en: 'Add to Cart', de: 'In den Warenkorb' },
  inCart: { en: 'In Cart', de: 'Im Warenkorb' },

  cartSummaryTitle: { en: 'Cart Summary', de: 'Warenkorbübersicht' },
  cartSummaryMyItems: { en: 'My Items', de: 'Meine Produkte' },
  cartSummarySubtotal: { en: 'Subtotal', de: 'Zwischensumme' },
  cartSummaryShipping: { en: 'Shipping', de: 'Versand' },
  cartSummaryShippingFree: { en: 'Free', de: 'Kostenlos' },
  cartSummaryTotal: { en: 'Total', de: 'Gesamt' },
  cartSummaryShippingProceedToCheckout: { en: 'Proceed to Checkout', de: 'Zur Kasse gehen' },

  orderSummaryTitle: { en: 'Order Summary', de: 'Bestellübersicht' },

  checkoutTitle: { en: 'Checkout', de: 'Kasse' },
  shippingTitle: { en: 'Shipping information', de: 'Versandinformationen' },
  shippingFirstName: { en: 'First name', de: 'Vorname' },
  shippingLastName: { en: 'Last name', de: 'Nachname' },
  shippingPhone: { en: 'Phone', de: 'Telefon' },
  shippingAddress: { en: 'Address', de: 'Adresse' },
  shippingCity: { en: 'City', de: 'Stadt' },
  shippingZipCode: { en: 'Zip code', de: 'PLZ', uk: 'Postcode' },
  shippingState: { en: 'State / Province', de: 'Staat/Provinz' },

  quickPayTitle: { en: 'Quick Pay', de: 'Schnelle Bezahlung' },
  paymentTitle: { en: 'Payment', de: 'Zahlung' },
  payWith: { en: 'Pay with', de: 'Bezahlen mit' },

  orderConfirmationTitle: { en: 'Order Confirmed', de: 'Bestellung bestätigt' },
  orderConfirmationFirstLine: {
    en: 'Dear %firstName %lastName,',
    de: 'Sehr geehrter %firstName %lastName,'
  },
  orderConfirmationSecondLine: {
    en: [
      'Thank you for placing an order with %shopName.',
      'We are pleased to confirm the receipt of your order %orderNumber, dated %orderDate.'
    ].join(' '),

    de: [
      'Vielen Dank, dass Sie eine Bestellung bei %shopName aufgegeben haben.',
      'Gerne bestätigen wir den Eingang Ihrer Bestellung %orderNumber vom %orderDate.'
    ].join(' ')
  },
  orderConfirmationThirdLine: {
    en: [
      'Thank you for choosing %shopName.',
      'We value your business and look forward to serving you again.'
    ].join(' '),
    de: [
      'Vielen Dank, dass Sie sich für %shopName entschieden haben.',
      'Wir schätzen Ihr Geschäft und freuen uns, Sie wieder bedienen zu dürfen.'
    ].join(' ')
  },
  orderConfirmationDetails: { en: 'Order Details', de: 'Bestelldetails' },

  shippingInvalidTitle: { en: 'Shipping', de: 'Versand' },
  shippingInvalidBody: {
    en: 'Please fill in shipping information to proceed.',
    de: 'Bitte geben Sie die Versandinformationen ein, um fortzufahren.'
  },
  shippingInvalidButton: { en: 'Got it', de: 'Habe es' },

  finishTitle: {
    en: 'App experience is now complete.',
    de: 'Das App-Erlebnis ist nun abgeschlossen.'
  },
  middleIntroFirst: {
    en: "Here's the second version of the experience. Again, please choose PayPal as the payment option.",
    de: "Hier ist die zweite Version des Erlebnisses. Wählen Sie erneut PayPal als Zahlungsoption."
  },
  middleIntroFirstButton: {
    en: "Start",
    de: "Start"
  },
  middleIntroLast: {
    en: "Here's the third and final experience. Again, please choose PayPal as the payment option.",
    de: "Hier ist die dritte und letzte Erfahrung. Wählen Sie erneut PayPal als Zahlungsoption."
  },
  middleIntroLastButton: {
    en: "Start",
    de: "Start"
  },
  finishButton: {
    en: 'Please click here to return to the survey',
    de: 'Klicken Sie hier, um zur Umfrage zurückzukehren'
  }
};

const translate = (key, countryCode = 'us') => (
  translations[key][countryCode] ? translations[key][countryCode] : translations[key].en
);

export { translate };


