import ProductImage1 from '../../../../../images/paypal/checkout/products/1.jpg';
import ProductImage2 from '../../../../../images/paypal/checkout/products/2.jpg';
import ProductImage3 from '../../../../../images/paypal/checkout/products/3.jpg';
import ProductImage4 from '../../../../../images/paypal/checkout/products/4.jpg';
import ProductImage5 from '../../../../../images/paypal/checkout/products/5.jpg';
import ProductImage6 from '../../../../../images/paypal/checkout/products/6.jpg';
import ProductImage7 from '../../../../../images/paypal/checkout/products/7.jpg';
import ProductImage8 from '../../../../../images/paypal/checkout/products/8.jpg';

export const fakeData = {
  maxCartItems: 10,
  priceWithSign: (countryCode) => {
    switch (countryCode) {
      case "de":
        return (price) => (`${`${price}`.replace('.', ',')} €`);
      case "uk":
        return (price) => (`£${price}`);
      default:
        return (price) => (`$${price}`);
    }
  },
  order: {
    number: 123456,
    date: new Date().toLocaleDateString(),
    shop: "Hifishop"
  },

  productsList: [
    { id: 1, name: "Sony WH-1000XM4", price: 349.99 },
    { id: 2, name: "Bose QuietComfort 35 II", price: 299 },
    { id: 3, name: "Sennheiser HD 660 S", price: 499.99 },
    { id: 4, name: "Audio-Technica ATH-M50x", price: 149 },
    { id: 5, name: "Beyerdynamic DT 770 PRO", price: 159 },
    { id: 6, name: "JBL Club One", price: 249 },
    { id: 7, name: "AKG K371", price: 149 },
    { id: 8, name: "Shure AONIC 50", price: 299 }
  ],

  productImages: [ ProductImage1, ProductImage2, ProductImage3, ProductImage4, ProductImage5, ProductImage6, ProductImage7, ProductImage8 ],

  allPaymentLists: {
    paypal: {
      id: "paypal",
      title: (countryCode) => ("PayPal"),
      icon: "paypal-raw",
      'icon-cell1': "paypal",
      radioText: true,
      buttonText: false,
      optionIcon: "paypal-raw",
      'optionIcon-cell1': "paypal-option",
      statisticsId: 1,
      main: true
    },
    googlePay: {
      id: "google-pay",
      title: (countryCode) => ("Google Pay"),
      icon: "google-pay",
      radioText: true,
      buttonText: false,
      optionIcon: "google-pay",
      statisticsId: 2,
      main: false
    },
    applePay: {
      id: "apple-pay",
      title: (countryCode) => ("Apple Pay"),
      icon: "apple-pay",
      radioText: true,
      buttonText: false,
      optionIcon: "apple-pay",
      statisticsId: 3,
      main: false
    },
    amazonPay: {
      id: "amazon-pay",
      title: (countryCode) => ("Amazon Pay"),
      icon: "amazon-pay",
      radioText: true,
      buttonText: false,
      optionIcon: "amazon-pay",
      statisticsId: 4,
      main: false
    },
    creditCard: {
      id: "credit-card",
      title: (countryCode) => (countryCode === 'de' ? "Kredit-oder Debitkarte" : "Credit or Debit Card"),
      radioText: true,
      buttonText: true,
      icon: null,
      optionIcons: [ "visa", "mastercard" ],
      statisticsId: 5,
      main: false
    },
    directPay: {
      id: "direct-pay",
      title: (countryCode) => (countryCode === 'de' ? "Direktzahlung / Bankkonto" : "Direct Pay / Bank Account"),
      radioText: true,
      buttonText: true,
      icon: null,
      optionIcon: null,
      statisticsId: 6,
      main: false
    },
    sofort: {
      id: "sofort",
      title: (countryCode) => (countryCode === 'de' ? "Sofortüberweisung" : "Sofort"),
      icon: "sofort",
      radioText: true,
      buttonText: true,
      optionIcon: "sofort",
      statisticsId: 7,
      main: false
    },
    invoice: {
      id: "invoice",
      title: (countryCode) => (countryCode === 'de' ? "Rechnung" : "Invoice"),
      icon: null,
      radioText: true,
      buttonText: true,
      optionIcon: null,
      statisticsId: 8,
      main: false
    },
    klarna: {
      id: "klarna",
      title: (countryCode) => ("Klarna"),
      icon: "klarna",
      radioText: true,
      buttonText: false,
      optionIcon: "klarna",
      statisticsId: 9,
      main: false
    },
    shopPay: {
      id: "shop-pay",
      title: (countryCode) => ("Shop Pay"),
      icon: "shop-pay",
      radioText: true,
      buttonText: false,
      optionIcon: "shop-pay",
      statisticsId: 10,
      main: false
    }
  },
  shippingInfo: (countryCode) => {
    const basePart = {
      email: "jordandoe@gmail.com",
      firstName: "Jordan",
      lastName: "Doe",
      price: 0
    };

    switch (countryCode) {
      case "de":
        return {
          ...basePart,
          ...{
            phone: "+49 12 3456 7890",
            address: "Röntgenstr. 9",
            city: "MAXDORF",
            state: "Rhineland-Palatinate",
            showState: false,
            zipCode: "67133"
          }
        };
      case "uk":
        return {
          ...basePart,
          ...{
            phone: "+44 12 3456 7890",
            address: "49 Featherstone Street",
            city: "London",
            state: "Greater London",
            showState: false,
            zipCode: "EC1Y 2SY"
          }
        };
      default:
        return {
          ...basePart,
          ...{
            phone: "+1 123-456-7890",
            address: "123 Sesame Street",
            city: "New York",
            state: "NY",
            showState: true,
            zipCode: "10000"
          }
        };
    }
  },
  navKeysForTranslation: [
    "navigationHome",
    "navigationProducts",
    "navigationAbout",
    "navigationSupport",
    "navigationContact"
  ]
};
