import React, { useContext } from 'react';
import { GlobalContext } from "../context";
import { calcTotal, calcSubTotal } from '../helper';

const Total = () => {
  const {
    cart, translate, countryCode, priceWithSign, shippingInfo
  } = useContext(GlobalContext);

  const subTotal = calcSubTotal(cart);
  const total = calcTotal(cart, shippingInfo.price);

  return (
    <div className="total">
      <div className="total_line">
        <div className="total_name">{ translate("cartSummarySubtotal", countryCode) }</div>
        <div className="total_value">
          { subTotal > 0 ? priceWithSign(subTotal) : 0 }
        </div>
      </div>
      <div className="total_line">
        <div className="total_name">{ translate("cartSummaryShipping", countryCode) }</div>
        <div className="total_value">
          { shippingInfo.price === 0 ? translate("cartSummaryShippingFree", countryCode) : shippingInfo.price }
        </div>
      </div>
      <div className="total_line">
        <div className="total_name">{ translate("cartSummaryTotal", countryCode) }</div>
        <div className="total_value -lg">
          { total > 0 ? priceWithSign(total) : 0 }
        </div>
      </div>
    </div>
  );
};

export default Total;
