import React, { useState, useEffect, forwardRef } from "react";

const FormControl = ({
  initialText = '', label, type = "text",
  onClick, onFocus, onBlur, onChange, disableChange,
  disabled, required
}, forwardedRef) => {
  const [ value, setValue ] = useState(initialText);
  useEffect(() => {
    setValue(initialText);
  }, [ initialText ]);

  const handleChange = (event) => {
    if (disableChange) {
      return;
    }
    const newValue = event.target.value;
    onChange && onChange(newValue);
    setValue(newValue);
  };

  const handleClick = (event) => {
    onClick && onClick(event);
  };

  const handleOnFocus = (event) => {
    onFocus && onFocus(event);
  };

  const handleOnBlur = (event) => {
    onBlur && onBlur(event);
  };

  return (
    <>
      <div className={ `form-control` }>
        {!!label && <label className="form-control_label">
          { label }{!!required && "*"}
        </label>}
        <input
          disabled={ disabled }
          name="test"
          className="form-control_field"
          type={ type }
          value={ value }
          onChange={ handleChange }
          onClick={ handleClick }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
          autoComplete="off"
          ref={ forwardedRef }
        />
      </div>
    </>
  );
};

export default forwardRef(FormControl);
