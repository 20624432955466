import { each, find, map, uniq, compact, values, sum } from 'lodash';
import { calcTotal } from './helper';

const initTime = () => (Date.now());
const totalTimeMs = (startTime) => (Date.now() - startTime);
const totalTime = (startTime) => Math.ceil(totalTimeMs(startTime) / 1000);

const prefixByFlowIndex = (flowIndex) => {
  if (!flowIndex) {
    return 'firstFlow';
  } else if (flowIndex === 1) {
    return 'secondFlow';
  }
  return 'thirdFlow';
};

const fieldNameByFlowIndex = (flowIndex, field) => (
  `${prefixByFlowIndex(flowIndex)}${field}`
);

const findCountryCodeId = (countryCode) => (
  { us: 1, uk: 2, de: 3 }[countryCode] || 1
);

const initialData = () => ({
  countryCodeId: null,
  cell: null,
  startTime: null,
  totalTimeMs: null,
  totalTime: null,
  // First flow
  firstFlowRadioButtonsClicked: [],
  firstFlowRadioButtonsClickedMs: [],
  firstFlowPaymentButtonTimeStamp: null,
  firstFlowId: null,
  firstFlowSelectedPaymentId: null,
  firstFlowCartTotal: 0,
  firstFlowCartTotalIntegerPart: 0,
  firstFlowCartTotalFloatPart: 0,
  firstFlowCartProductsCount: 0,
  firstFlowCartItemsCount: 0,
  firstFlowCartSelectedProducts: [],
  firstFlowCartFirstProductId: null,
  firstFlowCartFirstProductItemsCount: 0,
  firstFlowCartSecondProductId: null,
  firstFlowCartSecondProductItemsCount: 0,
  firstFlowCartThirdProductId: null,
  firstFlowCartThirdProductItemsCount: 0,
  firstFlowCartFourthProductId: null,
  firstFlowCartFourthProductItemsCount: 0,
  firstFlowCartFifthProductId: null,
  firstFlowCartFifthProductItemsCount: 0,
  firstFlowCartSixthProductId: null,
  firstFlowCartSixthProductItemsCount: 0,
  firstFlowCartSeventhProductId: null,
  firstFlowCartSeventhProductItemsCount: 0,
  firstFlowCartEighthProductId: null,
  firstFlowCartEighthProductItemsCount: 0,
  firstFlowRadioClicksInPaymentCount: 0,
  firstFlowRadioClicksInPayment: [],
  firstFlowRadioPayPalIndexFirstClick: null,
  firstFlowRadioPayPalIndexLastClick: null,
  firstFlowTimeFirstRadioClickToPaymentSelect: null,
  firstFlowTimeFirstRadioClickToPaymentSelectMs: null,
  firstFlowTimeLastRadioClickToPaymentSelect: null,
  firstFlowTimeLastRadioClickToPaymentSelectMs: null,
  firstFlowStartTime: null,
  firstFlowTotalTime: null,
  firstFlowTotalTimeMs: null,
  firstFlowProductsStartTime: null,
  firstFlowProductsTotalTime: null,
  firstFlowProductsTotalTimeMs: null,
  firstFlowProductsCartClick: false,
  firstFlowProductsHeaderLinkClick: false,
  firstFlowProductsInCartClick: false,
  firstFlowCheckoutStartTime: null,
  firstFlowCheckoutTotalTime: null,
  firstFlowCheckoutTotalTimeMs: null,
  firstFlowCheckoutPlusItemClick: false,
  firstFlowCheckoutMinusItemClick: false,
  firstFlowCheckoutDeleteProductClick: false,
  firstFlowCheckoutHeaderLinkClick: false,
  firstFlowShippingStartTime: null,
  firstFlowShippingTotalTime: null,
  firstFlowShippingTotalTimeMs: null,
  firstFlowShippingHeaderLinkClick: false,
  // Second flow
  secondFlowRadioButtonsClicked: [],
  secondFlowRadioButtonsClickedMs: [],
  secondFlowPaymentButtonTimeStamp: null,
  secondFlowId: null,
  secondFlowSelectedPaymentId: null,
  secondFlowCartTotal: 0,
  secondFlowCartTotalIntegerPart: 0,
  secondFlowCartTotalFloatPart: 0,
  secondFlowCartProductsCount: 0,
  secondFlowCartItemsCount: 0,
  secondFlowCartSelectedProducts: [],
  secondFlowCartFirstProductId: null,
  secondFlowCartFirstProductItemsCount: 0,
  secondFlowCartSecondProductId: null,
  secondFlowCartSecondProductItemsCount: 0,
  secondFlowCartThirdProductId: null,
  secondFlowCartThirdProductItemsCount: 0,
  secondFlowCartFourthProductId: null,
  secondFlowCartFourthProductItemsCount: 0,
  secondFlowCartFifthProductId: null,
  secondFlowCartFifthProductItemsCount: 0,
  secondFlowCartSixthProductId: null,
  secondFlowCartSixthProductItemsCount: 0,
  secondFlowCartSeventhProductId: null,
  secondFlowCartSeventhProductItemsCount: 0,
  secondFlowCartEighthProductId: null,
  secondFlowCartEighthProductItemsCount: 0,
  secondFlowRadioClicksInPaymentCount: 0,
  secondFlowRadioClicksInPayment: [],
  secondFlowRadioPayPalIndexFirstClick: null,
  secondFlowRadioPayPalIndexLastClick: null,
  secondFlowTimeFirstRadioClickToPaymentSelect: null,
  secondFlowTimeFirstRadioClickToPaymentSelectMs: null,
  secondFlowTimeLastRadioClickToPaymentSelect: null,
  secondFlowTimeLastRadioClickToPaymentSelectMs: null,
  secondFlowStartTime: null,
  secondFlowTotalTime: null,
  secondFlowTotalTimeMs: null,
  secondFlowProductsStartTime: null,
  secondFlowProductsTotalTime: null,
  secondFlowProductsTotalTimeMs: null,
  secondFlowProductsCartClick: false,
  secondFlowProductsHeaderLinkClick: false,
  secondFlowProductsInCartClick: false,
  secondFlowCheckoutStartTime: null,
  secondFlowCheckoutTotalTime: null,
  secondFlowCheckoutTotalTimeMs: null,
  secondFlowCheckoutPlusItemClick: false,
  secondFlowCheckoutMinusItemClick: false,
  secondFlowCheckoutDeleteProductClick: false,
  secondFlowCheckoutHeaderLinkClick: false,
  secondFlowShippingStartTime: null,
  secondFlowShippingTotalTime: null,
  secondFlowShippingTotalTimeMs: null,
  secondFlowShippingHeaderLinkClick: false,
  // Third flow
  thirdFlowRadioButtonsClicked: [],
  thirdFlowRadioButtonsClickedMs: [],
  thirdFlowPaymentButtonTimeStamp: null,
  thirdFlowId: null,
  thirdFlowSelectedPaymentId: null,
  thirdFlowCartTotal: 0,
  thirdFlowCartTotalIntegerPart: 0,
  thirdFlowCartTotalFloatPart: 0,
  thirdFlowCartProductsCount: 0,
  thirdFlowCartItemsCount: 0,
  thirdFlowCartSelectedProducts: [],
  thirdFlowCartFirstProductId: null,
  thirdFlowCartFirstProductItemsCount: 0,
  thirdFlowCartSecondProductId: null,
  thirdFlowCartSecondProductItemsCount: 0,
  thirdFlowCartThirdProductId: null,
  thirdFlowCartThirdProductItemsCount: 0,
  thirdFlowCartFourthProductId: null,
  thirdFlowCartFourthProductItemsCount: 0,
  thirdFlowCartFifthProductId: null,
  thirdFlowCartFifthProductItemsCount: 0,
  thirdFlowCartSixthProductId: null,
  thirdFlowCartSixthProductItemsCount: 0,
  thirdFlowCartSeventhProductId: null,
  thirdFlowCartSeventhProductItemsCount: 0,
  thirdFlowCartEighthProductId: null,
  thirdFlowCartEighthProductItemsCount: 0,
  thirdFlowRadioClicksInPaymentCount: 0,
  thirdFlowRadioClicksInPayment: [],
  thirdFlowRadioPayPalIndexFirstClick: null,
  thirdFlowRadioPayPalIndexLastClick: null,
  thirdFlowTimeFirstRadioClickToPaymentSelect: null,
  thirdFlowTimeFirstRadioClickToPaymentSelectMs: null,
  thirdFlowTimeLastRadioClickToPaymentSelect: null,
  thirdFlowTimeLastRadioClickToPaymentSelectMs: null,
  thirdFlowStartTime: null,
  thirdFlowTotalTime: null,
  thirdFlowTotalTimeMs: null,
  thirdFlowProductsStartTime: null,
  thirdFlowProductsTotalTime: null,
  thirdFlowProductsTotalTimeMs: null,
  thirdFlowProductsCartClick: false,
  thirdFlowProductsHeaderLinkClick: false,
  thirdFlowProductsInCartClick: false,
  thirdFlowCheckoutStartTime: null,
  thirdFlowCheckoutTotalTime: null,
  thirdFlowCheckoutTotalTimeMs: null,
  thirdFlowCheckoutPlusItemClick: false,
  thirdFlowCheckoutMinusItemClick: false,
  thirdFlowCheckoutDeleteProductClick: false,
  thirdFlowCheckoutHeaderLinkClick: false,
  thirdFlowShippingStartTime: null,
  thirdFlowShippingTotalTime: null,
  thirdFlowShippingTotalTimeMs: null,
  thirdFlowShippingHeaderLinkClick: false
});

const processCartData = (flowIndex, respondentData, cartData, shippingInfo) => {
  const total = calcTotal(cartData, shippingInfo.price);
  const totalArr = `${total}`.split('.');
  const totalInteger = parseInt(totalArr[0]);
  const totalFloat = totalArr.length > 1 ? parseInt(totalArr[1]) : 0;
  const cartProductsCount = cartData.length;
  const cartProductItemsCount = sum(map(cartData, (el) => el?.amount));

  respondentData[fieldNameByFlowIndex(flowIndex, 'CartTotal')] = total;
  respondentData[fieldNameByFlowIndex(flowIndex, 'CartTotalIntegerPart')] = totalInteger;
  respondentData[fieldNameByFlowIndex(flowIndex, 'CartTotalFloatPart')] = totalFloat;
  respondentData[fieldNameByFlowIndex(flowIndex, 'CartProductsCount')] = cartProductsCount;
  respondentData[fieldNameByFlowIndex(flowIndex, 'CartItemsCount')] = cartProductItemsCount;
  respondentData[fieldNameByFlowIndex(flowIndex, 'CartSelectedProducts')] = map(
    cartData, (el) => el?.product?.id
  );

  each(
    [ 'First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth' ],
    (el, index) => {
      if (cartData.length > index) {
        respondentData[fieldNameByFlowIndex(flowIndex, `Cart${el}ProductId`)] = cartData[index]?.product?.id;
        respondentData[fieldNameByFlowIndex(flowIndex, `Cart${el}ProductItemsCount`)] = cartData[index]?.amount;
      }
    }
  );
};

const sendData = (customData = {}, allPaymentsList) => {
  const sendingData = customData || {};
  sendingData.totalTime = totalTime(customData.startTime);
  sendingData.totalTimeMs = totalTimeMs(customData.startTime);
  const flowIndices = [ 0, 1, 2 ];
  each(
    flowIndices,
    (flowIndex) => {
      const clickedArr = sendingData[fieldNameByFlowIndex(flowIndex, 'RadioButtonsClicked')];
      sendingData[fieldNameByFlowIndex(flowIndex, 'RadioClicksInPaymentCount')] = clickedArr.length;
      sendingData[fieldNameByFlowIndex(flowIndex, 'RadioClicksInPayment')] = compact(
        uniq(
          map(
            clickedArr,
            (paymentId) => (
              find(values(allPaymentsList), (item) => item.id === paymentId)?.statisticsId
            )
          )
        )
      );
    }
  );

  const mainId = find(values(allPaymentsList), (el) => el.main)?.id;
  each(
    flowIndices,
    (flowIndex) => {
      const clickedArr = sendingData[fieldNameByFlowIndex(flowIndex, 'RadioButtonsClicked')];
      const mainIndices = compact(
        map(
          clickedArr,
          (el, index) => {
            return el === mainId ? index + 1 : null;
          }
        )
      );
      if (mainIndices.length) {
        sendingData[fieldNameByFlowIndex(flowIndex, 'RadioPayPalIndexFirstClick')] = mainIndices[0];
        sendingData[fieldNameByFlowIndex(flowIndex, 'RadioPayPalIndexLastClick')] = mainIndices[mainIndices.length - 1];
      }
    }
  );

  each(
    flowIndices,
    (flowIndex) => {
      const clickedArrTimestamps = sendingData[fieldNameByFlowIndex(flowIndex, 'RadioButtonsClickedMs')];
      const paymentClickTimestamp = sendingData[fieldNameByFlowIndex(flowIndex, 'PaymentButtonTimeStamp')];

      if (clickedArrTimestamps.length) {
        const diffFirstRadioAndPaymentMs = (paymentClickTimestamp - clickedArrTimestamps[0]);
        const diffFirstRadioAndPaymentSec = Math.ceil(diffFirstRadioAndPaymentMs / 1000);

        const diffLastRadioAndPaymentMs = (
          paymentClickTimestamp - clickedArrTimestamps[clickedArrTimestamps.length - 1]
        );
        const diffLastRadioAndPaymentSec = Math.ceil(diffLastRadioAndPaymentMs / 1000);

        sendingData[fieldNameByFlowIndex(flowIndex, 'TimeFirstRadioClickToPaymentSelect')] = diffFirstRadioAndPaymentSec;
        sendingData[fieldNameByFlowIndex(flowIndex, 'TimeFirstRadioClickToPaymentSelectMs')] = diffFirstRadioAndPaymentMs;
        sendingData[fieldNameByFlowIndex(flowIndex, 'TimeLastRadioClickToPaymentSelect')] = diffLastRadioAndPaymentSec;
        sendingData[fieldNameByFlowIndex(flowIndex, 'TimeLastRadioClickToPaymentSelectMs')] = diffLastRadioAndPaymentMs;
      }
    }
  );

  const postData = { result: sendingData, kind: 'finishStimuli' };

  (window.parent || window).postMessage(postData, "*");
};

export {
  initTime, totalTime, totalTimeMs, findCountryCodeId,
  prefixByFlowIndex, fieldNameByFlowIndex,
  initialData, processCartData, sendData
};
