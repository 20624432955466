import 'whatwg-fetch';
import { map } from 'lodash';
import { Promise } from 'es6-promise';

const sendResponse = (response, resolve) => {
  response.text().then(
    (data) => {
      let json;
      if (data) {
        try { json = JSON.parse(data); } catch (e) {}
      }
      resolve({
        response,
        data: json || data
      });
    }
  );
};

const Http = {
  get: (url, params, options = {}) => {
    const { parametersAsString } = options;
    let stringParams;
    if (parametersAsString) {
      stringParams = params;
    } else {
      stringParams = map(params, (value, key) => `${key}=${value}`).join('&');
    }
    const stringUrl = `${url}${url.match(/\?/) ? '' : '?'}${stringParams}`;
    return new Promise((resolve, reject) => {
      fetch(stringUrl, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      }).then(
        (response) => { sendResponse(response, resolve); },
        (response) => { sendResponse(response, reject); }
      );
    });
  },

  post: (url, params) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      }).then(
        (response) => { sendResponse(response, resolve); },
        (response) => { sendResponse(response, reject); }
      );
    });
  },

  postForAsyncCopy: (url, params, urlField, callback, onBadResponse) => {
    let tempResponse;
    return fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(params)
    })
      .then((response) => {
        tempResponse = response;
        return response.text();
      })
      .then((text) => {
        if (!tempResponse.ok && onBadResponse) {
          onBadResponse(tempResponse);
          return null;
        }

        const url = JSON.parse(text)[urlField];
        return callback ? callback(url) : (new Blob([ url ], { type: "text/plain" }));
      });
  },

  put: (url, params) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      }).then(
        (response) => { sendResponse(response, resolve); },
        (response) => { sendResponse(response, reject); }
      );
    });
  },

  del: (url, params) => {
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(params)
      }).then(
        (response) => { sendResponse(response, resolve); },
        (response) => { sendResponse(response, reject); }
      );
    });
  }
};

export default Http;
