import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../context';
import { initTime, totalTime, totalTimeMs } from '../statistics';

const BaseCartWrapper = ({ children, lastStep }) => {
  const { mergeDataFlow, valueByFlowIndex, flowIndex } = useContext(GlobalContext);

  useEffect(() => {
    mergeDataFlow(flowIndex, { CheckoutStartTime: initTime() });
    return () => {
      if (!lastStep) {
        mergeDataFlow(
          flowIndex,
          {
            CheckoutTotalTime: totalTime(valueByFlowIndex(flowIndex, 'CheckoutStartTime')),
            CheckoutTotalTimeMs: totalTimeMs(valueByFlowIndex(flowIndex, 'CheckoutStartTime'))
          }
        );
      }
    };
  }, []);

  return children;
};

export default BaseCartWrapper;
