import { map } from "lodash";
import React, { useContext } from "react";
import { GlobalContext } from "../context";
import CartItem from "./cartItem";

const CartList = () => {
  const { cart } = useContext(GlobalContext);

  return (
    <ul className="cart-list">
      {
        map(cart, (cartItem, index) => (
          <li key={ index } className="cart-list_item">
            <CartItem item={ cartItem } />
          </li>
        ))
      }
    </ul>
  );
};

export default CartList;

