import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import renderRawHtml from '../../../../common/render_raw_html';

const Portal = ({ children }) => {
  const body = document.body || document.querySelector('body');
  const html = document.html || document.querySelector('html');

  const [ element ] = useState(document.createElement('div'));

  useEffect(() => {
    body.appendChild(element);
    body.style.overflow = 'hidden';
    return () => {
      body.removeChild(element);
      body.style.overflow = null;
      //hack for safari to correct behavior of modal in iframe
      const topCoord = html.scrollTop;
      html.scrollTo({ top: 0 });
      html.scrollTo({ top: topCoord });
    };
  }, [ body, element, html ]);


  return createPortal(children, element);
};

const Modal = ({
  headerTitle, handleClose,
  className, children, modal
}) => {
  const onClose = () => {
    if (modal) {
      return;
    }
    handleClose && handleClose();
  };

  return (
    <Portal>
      <div className={ `modal ${className ? className : ''}` }>
        <div
          className="modal_backdrop"
          onClick={ () => { onClose(); } }
        />
        <div className="modal_dialog">
          {
            <div className={ classnames("modal_header") }>
              {!!headerTitle && <h2 className="modal_title" { ...renderRawHtml(headerTitle) } />}
            </div>
          }
          <div className="modal_content">
            {children}
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;
