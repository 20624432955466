import React, { useContext, useEffect, useState } from 'react';
import { find, map } from "lodash";
import { GlobalContext } from "../context";
import Layout from "../components/layout";
import { initTime, totalTime, findCountryCodeId, totalTimeMs } from '../statistics';

const ProductItem = ({ product }) => {
  const {
    goToCart, cart, changeCart, fakeData, translate, countryCode, priceWithSign,
    flowIndex, mergeDataFlow
  } = useContext(GlobalContext);

  const addToCartHandle = (product) => {
    const cartItem = {
      product,
      amount: 1
    };
    cart.push(cartItem);
    changeCart([ ...cart ]);
  };

  const goToCartHandle = () => {
    mergeDataFlow(flowIndex, { ProductsInCartClick: true });
    goToCart();
  };

  const checkProductInCart = (productId) => {
    return find(cart, (cartItem) => (cartItem.product.id === productId));
  };

  return (
    <div className="product">
      <div className="product_image">
        <img src={ fakeData.productImages[product.id - 1] } alt={ product.name } />
      </div>
      <div className="product_info">
        <h5 className="product_title" title={ product.name }>
          { product.name }
        </h5>
        <div className="product_price">
          { priceWithSign(product.price) }
        </div>
      </div>
      <div className="product_button">
        {checkProductInCart(product.id) ? (
          <button className="button -active -full-width" onClick={ goToCartHandle }>
            { translate('inCart', countryCode) }
          </button>
        ) : (
          <button className="button -full-width" onClick={ () => addToCartHandle(product) }>
            { translate('addToCart', countryCode) }
          </button>
        )}
      </div>
    </div>
  );
};

const Products = ({ initialLoading = true }) => {
  const {
    fakeData, flowIndex, mergeData, mergeDataFlow, valueByFlowIndex, currentFlow,
    countryCode, cell
  } = useContext(GlobalContext);
  const productsList = fakeData.productsList;
  const [ loading, setLoading ] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, initialLoading ? 500 : 100);
    return () => {
      mergeDataFlow(
        flowIndex,
        {
          ProductsTotalTime: totalTime(valueByFlowIndex(flowIndex, 'ProductsStartTime')),
          ProductsTotalTimeMs: totalTimeMs(valueByFlowIndex(flowIndex, 'ProductsStartTime'))
        }
      );
    };
  }, []);

  useEffect(() => {
    const startTime = initTime();
    if (!flowIndex && initialLoading && !loading) {
      mergeData({ startTime });
      mergeData({ countryCodeId: findCountryCodeId(countryCode) });
      mergeData({ cell });
    }
    if (initialLoading && !loading) {
      mergeDataFlow(flowIndex, { StartTime: startTime });
      mergeDataFlow(flowIndex, { Id: currentFlow });
    }
    if (!loading) {
      mergeDataFlow(flowIndex, { ProductsStartTime: startTime });
    }
  }, [ loading ]);


  return (
    <Layout loading={ loading }>
      <div className="products">
        <ul className="products_list">
          {
            map(productsList, (product, index) => (
              <li key={ index } className="products_list-item">
                <ProductItem product={ product } />
              </li>
            ))
          }
        </ul>
      </div>
    </Layout>
  );
};

export default Products;


