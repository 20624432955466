import React, { useState, useEffect } from 'react';
import { fakeData } from '../fakeData';
import { GlobalContext } from '../context';
import { translate } from '../translations';
import { totalTime, processCartData, totalTimeMs } from '../statistics';
import Modal from './modal';

const BaseFlowWrapper = ({
  children, initialStep = 'products', countryCode, cell,
  flowIndex, currentFlow, onNextFlow,
  respondentsDataStore, mergeData, mergeDataFlow, valueByFlowIndex
}) => {
  const [ cartState, setCartState ] = useState([]);
  const [ step, setStep ] = useState(initialStep);
  const [ initialLoading, setInitialLoading ] = useState(true);
  const [ shippingValid, setShippingValid ] = useState(false);
  const [ confirmedModalShow, setConfirmedModalShow ] = useState(false);
  const [ customModalObj, setCustomModalObj ] = useState(null);

  const goToProducts = () => {
    setInitialLoading(false);
    setStep('products');
  };
  const goToCart = () => {
    setStep('cart');
  };
  const goToStart = () => {
    setCartState([]);
    setStep(initialStep);
    setInitialLoading(true);
    mergeDataFlow(
      flowIndex,
      {
        TotalTime: totalTime(valueByFlowIndex(flowIndex, 'StartTime')),
        TotalTimeMs: totalTimeMs(valueByFlowIndex(flowIndex, 'StartTime'))
      }
    );
    onNextFlow && onNextFlow();
  };

  const handleOrderNext = () => {
    setConfirmedModalShow(false);
    goToStart();
  };

  const goToPayment = () => {
    setStep('payment');
  };

  const shippingInfo = fakeData.shippingInfo(countryCode);

  const globalContextValue = {
    priceWithSign: fakeData.priceWithSign(countryCode),
    shippingInfo,
    countryCode,
    flowIndex,
    currentFlow,
    cell,
    translate,
    fakeData,
    cart: cartState,
    changeCart: setCartState,
    goToProducts,
    goToCart,
    goToPayment,
    goToStart,
    step, setStep,
    initialLoading,
    setConfirmedModalShow,
    shippingValid, setShippingValid,
    customModalObj, setCustomModalObj,

    respondentsDataStore, mergeData, mergeDataFlow, valueByFlowIndex
  };

  const closeOrderModal = () => {
    setConfirmedModalShow(false);
  };

  const closeCustomModal = () => {
    setCustomModalObj(null);
  };

  const orderConfirmationTitle = () => (translate("orderConfirmationTitle", countryCode));
  const orderConfirmationFirstLine = () => (
    translate("orderConfirmationFirstLine", countryCode)
      .replace('%firstName', shippingInfo.firstName)
      .replace('%lastName', shippingInfo.lastName)
  );
  const orderConfirmationSecondLine = () => (
    translate("orderConfirmationSecondLine", countryCode)
      .replace('%shopName', fakeData.order.shop)
      .replace('%orderNumber', fakeData.order.number)
      .replace('%orderDate', fakeData.order.date)
  );
  const orderConfirmationThirdLine = () => (
    translate("orderConfirmationThirdLine", countryCode)
      .replace('%shopName', fakeData.order.shop)
  );
  const orderConfirmationDetails = () => (translate("orderConfirmationDetails", countryCode));

  useEffect(() => {
    if (confirmedModalShow) {
      processCartData(flowIndex, respondentsDataStore.current, cartState, shippingInfo);
    }
  }, [ confirmedModalShow ]);


  return (
    <GlobalContext.Provider value={ globalContextValue }>
      <div className="container">
        { children }
        {
          confirmedModalShow &&
          <Modal
            modal
            handleClose={ closeOrderModal }
            headerTitle={ orderConfirmationTitle() }
          >
            <p>
              { orderConfirmationFirstLine() }
              <br />
              { orderConfirmationSecondLine() }
            </p>
            <p>{ orderConfirmationThirdLine() }</p>
            <p>
              <button className="button -red -full-width -sm" onClick={ handleOrderNext }>
                { orderConfirmationDetails() }
              </button>
            </p>
          </Modal>
        }
        {
          !!customModalObj &&
          <Modal handleClose={ closeCustomModal } headerTitle={ customModalObj.titleText }>
            { customModalObj.bodyText }
            <p>
              <button className="button -red -full-width -sm" onClick={ closeCustomModal }>
                { customModalObj.buttonText }
              </button>
            </p>
          </Modal>
        }
      </div>
    </GlobalContext.Provider>
  );
};

export default BaseFlowWrapper;
