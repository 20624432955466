import React, { useContext, useEffect } from 'react';
import { GlobalContext } from '../context';
import { initTime } from '../statistics';

const BasePaymentWrapper = ({ children }) => {
  const { mergeDataFlow, flowIndex } = useContext(GlobalContext);

  useEffect(() => {
    mergeDataFlow(flowIndex, { ShippingStartTime: initTime() });
  }, []);

  return children;
};

export default BasePaymentWrapper;
