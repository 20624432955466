import React, { useContext } from "react";
import { map } from "lodash";
import classnames from 'classnames';
import { GlobalContext } from "../context";

const Header = ({ headerFull }) => {
  const {
    goToCart, goToProducts, cart, fakeData, translate, countryCode,
    step, flowIndex, mergeDataFlow
  } = useContext(GlobalContext);

  const handleLogoClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    let fieldName;
    if (step === 'products') {
      fieldName = 'ProductsHeaderLinkClick';
    } else if (step === 'cart') {
      fieldName = 'CheckoutHeaderLinkClick';
    } else {
      fieldName = 'ShippingHeaderLinkClick';
    }
    mergeDataFlow(flowIndex, { [fieldName]: true });
    goToProducts();
  };

  const handleCartClick = () => {
    mergeDataFlow(flowIndex, { ProductsCartClick: true });
    goToCart();
  };

  return (
    <header
      className={ classnames(
        "header",
        { "-short": !headerFull }
      ) }
    >
      <div className="header_grid">
        {
          !!headerFull &&
          <div className="header_grid-item -show-mobile">
            <button className="button-icon">
              <svg className="icon -sandwich">
                <use xlinkHref="#svg_sandwich" />
              </svg>
            </button>
          </div>
        }
        <div className="header_grid-item -logo">
          <a href="#" className="logo" onClick={ handleLogoClick }>
            <svg className={ `logo_image` }>
              <use xlinkHref="#svg_logo-shop" />
            </svg>
          </a>
        </div>
        {
          !!headerFull &&
          <div className="header_grid-item -hide-mobile">
            <nav className="nav">
              {
                map(fakeData.navKeysForTranslation, (key, index) => (
                  <div key={ index } className="nav_item">
                    <div className="nav_link">
                      { translate(key, countryCode) }
                      <svg className={ `nav_link-icon` }>
                        <use xlinkHref="#svg_arrow-down" />
                      </svg>
                    </div>
                  </div>
                ))
              }
            </nav>
          </div>
        }
        {
          !!headerFull &&
          <div className="header_grid-item">
            <nav className="nav -secondary">
              <div className="nav_item -hide-mobile">
                <svg className="icon -search">
                  <use xlinkHref="#svg_search" />
                </svg>
              </div>
              <div className="nav_item -hide-mobile">
                <svg className="icon -user">
                  <use xlinkHref="#svg_user" />
                </svg>
              </div>
              <div className="nav_item">
                <button className="button-icon cart-button" onClick={ handleCartClick }>
                  <svg className="icon -cart cart-button_icon">
                    <use xlinkHref="#svg_cart" />
                  </svg>
                  { !!cart.length && <span className="cart-button_amount">{ cart.length }</span> }
                </button>
              </div>
            </nav>
          </div>
        }
      </div>
    </header>
  );
};

export default Header;
