import React from "react";
import classnames from 'classnames';
import Header from "./header";
import Loading from './loading';

const Layout = ({ headerFull = true, children, contentClassName, loading }) => {
  return (
    <>
      { !!loading && <Loading /> }
      <div className="layout">
        <div className="layout_header">
          <Header headerFull={ headerFull } />
        </div>
        <div className="layout_content">
          <main className={ classnames("content", contentClassName) }>
            { children }
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
