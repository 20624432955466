import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../context";
import FormControl from "./formControl";

const Shipping = () => {
  const { shippingInfo, setShippingValid, translate, countryCode } = useContext(GlobalContext);
  const [ firstName, setFirstName ] = useState(shippingInfo.firstName);
  const [ lastName, setLastName ] = useState(shippingInfo.lastName);
  const [ phone, setPhone ] = useState(shippingInfo.phone);
  const [ address, setAddress ] = useState(shippingInfo.address);
  const [ city, setCity ] = useState(shippingInfo.city);
  const [ zipCode, setZipCode ] = useState(shippingInfo.zipCode);
  const [ province, setProvince ] = useState(shippingInfo.state);

  const handleFirstNameChange = () => {
    setFirstName(shippingInfo.firstName);
  };
  const handleLastNameChange = () => {
    setLastName(shippingInfo.lastName);
  };
  const handlePhoneChange = () => {
    setPhone(shippingInfo.phone);
  };
  const handleAddressChange = () => {
    setAddress(shippingInfo.address);
  };
  const handleCityChange = () => {
    setCity(shippingInfo.city);
  };
  const handleZipCodeChange = () => {
    setZipCode(shippingInfo.zipCode);
  };
  const handleProvinceChange = () => {
    setProvince(shippingInfo.state);
  };

  useEffect(() => {
    const valid = (
      !!firstName && !!lastName && !!phone && !!address && !!city && !!zipCode && !!province
    );
    setShippingValid(valid);
  }, [ firstName, lastName, phone, address, city, zipCode, province ]);

  return (
    <div className="shipping">
      <h4 className="shipping_title">{ translate("shippingTitle", countryCode) }</h4>
      <div className="shipping_grid">
        <div className="shipping_grid-item -half">
          <FormControl
            disableChange
            initialText={ firstName }
            label={ translate("shippingFirstName", countryCode) }
            onClick={ handleFirstNameChange }
            required
          />
        </div>
        <div className="shipping_grid-item -half">
          <FormControl
            disableChange
            initialText={ lastName }
            label={ translate("shippingLastName", countryCode) }
            onClick={ handleLastNameChange }
            required
          />
        </div>
        <div className="shipping_grid-item">
          <FormControl
            disableChange
            initialText={ phone }
            label={ translate("shippingPhone", countryCode) }
            onClick={ handlePhoneChange }
            required
          />
        </div>
        <div className="shipping_grid-item">
          <FormControl
            disableChange
            initialText={ address }
            label={ translate("shippingAddress", countryCode) }
            onClick={ handleAddressChange }
            required
          />
        </div>
        <div className="shipping_grid-item -half">
          <FormControl
            disableChange
            initialText={ city }
            label={ translate("shippingCity", countryCode) }
            onClick={ handleCityChange }
            required
          />
        </div>
        <div className="shipping_grid-item -half">
          <FormControl
            disableChange
            initialText={ zipCode }
            label={ translate("shippingZipCode", countryCode) }
            onClick={ handleZipCodeChange }
            required
          />
        </div>
        {
          shippingInfo.showState &&
          <div className="shipping_grid-item">
            <FormControl
              disableChange
              initialText={ province }
              label={ translate("shippingState", countryCode) }
              onClick={ handleProvinceChange }
              required
            />
          </div>
        }
      </div>
    </div>
  );
};


export default Shipping;

