import React, { useContext } from "react";
import { GlobalContext } from "../context";

const CartItem = ({ item }) => {
  const {
    cart, changeCart, fakeData, priceWithSign,
    mergeDataFlow, flowIndex
  } = useContext(GlobalContext);

  const minItemsCount = 1;
  const maxItemsCount = fakeData.maxCartItems;

  const deleteFromCart = (itemId) => {
    const index = cart.findIndex((cartItem) => cartItem.product?.id === itemId);
    if (index > -1) {
      mergeDataFlow(flowIndex, { CheckoutDeleteProductClick: true });
      cart.splice(index, 1);
      changeCart([ ...cart ]);
    }
  };

  const increaseAmount = (itemId) => {
    const index = cart.findIndex((cartItem) => cartItem.product?.id === itemId);
    if (index > -1 && cart[index].amount < maxItemsCount) {
      mergeDataFlow(flowIndex, { CheckoutPlusItemClick: true });
      cart[index].amount = cart[index].amount + 1;
      changeCart([ ...cart ]);
    }
  };

  const decreaseAmount = (itemId) => {
    const index = cart.findIndex((cartItem) => cartItem.product?.id === itemId);
    if (index > -1) {
      if (cart[index].amount > minItemsCount) {
        mergeDataFlow(flowIndex, { CheckoutMinusItemClick: true });
        cart[index].amount = cart[index].amount - 1;
      }
      changeCart([ ...cart ]);
    }
  };

  return (
    <div className="cart-item">
      <div className="cart-item_image">
        <img src={ fakeData.productImages[item.product.id - 1] } alt={ item.product.name } />
      </div>
      <div className="cart-item_content">
        <div className="cart-item_info">
          <h5 className="cart-item_title">
            { item.product.name }
          </h5>
          <div className="cart-item_price">
            { priceWithSign(item.product.price) }
          </div>
        </div>
        <div className="cart-item_actions">
          <div className="amount-control">
            <button
              className="amount-control_button -minus"
              onClick={ () => decreaseAmount(item.product.id) }
              disabled={ item.amount === minItemsCount }
            >
              &ndash;
            </button>
            <span className="amount-control_value">{ item.amount }</span>
            <button
              className="amount-control_button -plus"
              onClick={ () => increaseAmount(item.product.id) }
              disabled={ item.amount === maxItemsCount }
            >
              +
            </button>
          </div>
          <button className="button-icon" onClick={ () => deleteFromCart(item.product.id) }>
            <svg className={ `icon -delete` }>
              <use xlinkHref="#svg_delete" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
