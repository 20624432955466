import React from 'react';
import { map, round, sum } from 'lodash';

const calcSubTotal = (cartData) => {
  return round(
    sum(
      map(cartData, (cartItem) => (cartItem.amount * cartItem.product.price))
    ), 2
  );
};

const calcTotal = (cartData, shippingPrice) => (calcSubTotal(cartData) + shippingPrice);

const shippingErrorModalData = (translate, countyCode) => ({
  titleText: translate("shippingInvalidTitle", countyCode),
  bodyText: (<p>{ translate("shippingInvalidBody", countyCode) }</p>),
  buttonText: (<>{ translate("shippingInvalidButton", countyCode) }</>)
});

export { shippingErrorModalData, calcSubTotal, calcTotal };
